import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: '首页',
    icon: 'HomeIcon',
    href: 'https://pancake.fniu.dev',
  },
  {
    label: '交易',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: '兑换',
        href: '/swap',
      },
      {
        label: '流动性',
        href: '/pool',
      },
    ],
  }
]

export default config
